@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Spectral+SC:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
html {
    scroll-behavior: smooth;
}
:root {
    --color-one: #5584ac;
    --color-two: #BDCDD6
}
h1, h2, h3, h4, h5, h6, th {
    font-family: "Spectral SC", serif;
}
p, button, a, input, textarea, ul, ol, td, label {
    font-family: "Noto Sans", sans-serif;
}
button, a {
    cursor: pointer;
    transition: 0.3125s;
}
/* button:hover {
    scale: 1.05;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2));
}
button:active {
    scale: 0.95;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.5));
} */

body {
    position: relative;
    margin: 0;
}
.for-mobile, #con-menu .for-mobile {
    display: none;
}
header {
    z-index: 300;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
    background-color: var(--color-one);
    color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}
header button.home {
    display: flex;
    align-items: center;
    gap: 15px;
    background: none;
    border: none;
}
header button.home img {
    height: 70px;
    border-radius: 50%;
}
header button.home h1 {
    color: white;
    font-style: italic;
    margin: none;
    font-size: 30px;
}
header .phone-number {
    margin-left: auto;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}
header button.contact {
    background: none;
    border: none;
}
header button.contact img {
    height: 70px;
}
header .quit {
    margin-left: auto;
    font-size: 15px;
    background: none;
    border: none;
    color: white;
}
#con-menu {
    user-select: none;
    position: absolute;
    right: 5px;
    top: 110px;
    padding: 5px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, .75);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .25);
    transition: .3125s;
}
#con-menu.closed {
    filter: blur(40px) brightness(1.5);
    opacity: 0;
    translate: 10% -10%;
    scale: .8;
    pointer-events: none;
}
#con-menu button {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    border: none;
    background: none;
}
#con-menu button img {
    height: 50px;
}
#con-menu button p {
    margin: 0;
    text-align: center;
}

main {
    margin: 10px;
    margin-top: 20px;
}
main button.back {
    border: none;
    font-size: 18px;
    border: none;
    background: none;
    color: var(--color-one);
    border-bottom: 1px solid transparent;
    align-self: flex-start;
}
main button.back:hover {
    border-color: var(--color-one);
}
#home .slogan {
    display: flex;
    flex-direction: column;
}
#home .slogan h2 {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}
#home .slogan h2 span {
    color: var(--color-one);
}
#home .slogan .line {
    background-color: var(--color-one);
    height: 5px;
    width: 80%;
}
#home .slogan h3 {
    margin: 0;
}
#home .news {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}
#home .news h3 {
    margin: 0;
    text-align: center;
    font-size: 40px;
}
#home .news .list {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding: 30px;
    overflow-x: auto;
    margin: -20px;
}
#home .news .list .post {
    position: relative;
    min-width: 300px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    border-radius: 20px;
    cursor: pointer;
    border-left: 1px solid var(--color-one);
    border-right: 1px solid var(--color-one);
}
#home .news .list .post h4 {
    font-weight: 600;
}
#home .news .list .post p {
    font-size: 14px;
    opacity: 0.7;
}
#home .news .list .post .date {
    position: absolute;
    font-size: 15px;
    top: -10px;
    right: 10px;
    opacity: 0.5;
}
#home .news .post.more {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-one);
    border: none;
}
#home .news .post.more p {
    margin: 0;
    font-size: 20px;
    font-family: "Spectral SC", serif;
    font-weight: bold;
    color: white;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
}

#home .pricing {
    margin: 10px -10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-one);
}
#home .pricing button {
    min-width: 60%;
    padding: 10px 15px;
    font-family: "Spectral SC", serif;
    font-size: 20px;
    border-radius: 15px;
    background: white;
    border: none;
}

#home .reasons .list {
    display: flex;
    align-items: stretch;
    gap: 10px;
    overflow-x: auto;
    padding: 10px;
}
#home .reasons .list .card {
    flex: 1;
    min-width: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 0.5px solid var(--color-one);
    border-radius: 15px;
    cursor: pointer;
}
#home .reasons .list .card h4 {
    margin: auto 0;
    text-transform: uppercase;
    font-weight: 500;
}
#home .reasons .list .card p {
    color: var(--color-two);
    text-transform: uppercase;
    text-decoration: underline;
}
#home .reasons .list .card img {
    width: 190px;
    border-radius: 10px;
}

#home .we-do {
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}
#home .we-do .item {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    text-align: center;
}
#home .we-do .item img {
    height: 50px;
}
#home .we-do .item h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-one);
}
#home .we-do .item p {
    margin: 0;
}

#home .result {
    background-color: var(--color-two);
    margin: 10px -10px;
    padding: 20px;
}
#home .result h2 {
    margin: 5px;
    margin-top: 20px;
    font-size: 30px;
    text-transform: uppercase;
}
#home .result h2 span {
    color: var(--color-one);
}
#home .result .list {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}
#home .result .list .item {
    padding: 10px;
    width: 45%;
    min-width: 40%;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, .25);
}
#home .result .list .item img {
    height: 40px;
}
#home .result .card-list {
    margin: 0 -20px;
    padding: 25px 4%;
    display: flex;
    gap: 10px;
    overflow-x: scroll;
}
#home .result .card-list .card {
    padding: 30px 15px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, .25);
    cursor: pointer;
}
#home .result .card-list .card p {
    margin: 0;
}
#home .result .card-list .card p.name {
    font-weight: bold;
    text-align: center;
    color: var(--color-one);
}
#home .result .card-list .card:hover p.name {
    text-decoration: underline;
}
#home .result .card-list .card p.economy {
    font-weight: bold;
    color: var(--color-one);
}
#home .contact {
    width: 95%;
    max-width: 800px;
    border-radius: 20px;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, .25);
    overflow: hidden;
}
#home .con {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
#home .con button {
    padding: 10px 15px;
    font-size: 20px;
    font-family: "Spectral SC", serif;
    border-radius: 10px;
    border: none;
    background-color: var(--color-one);
    color: white;
}
/* #home .bg {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    transition: .625s;
}
#home .bg.closed {
    filter: blur(20px);
    backdrop-filter: blur(0);
    -webkit-backdrop-filter: blur(0);
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    opacity: 0;
}
#home .bg .window {
    width: 80%;
    max-width: 300px;
    padding: 15px;
    border-radius: 20px;
    text-align: center;
    background-color: white;
    overflow: hidden;
}
#home .bg.closed .window {
    display: none;
}
#home .window .confirm {
    display: flex;
    justify-content: center;
    gap: 10px;
}
#home .window button {
    flex: 1;
    background: none;
    border: none;
    font-size: 15px;
    font-weight: bold;
    color: var(--color-one);
    border-bottom: 1px solid transparent;
}
#home .window button:hover {
    border-bottom-color: var(--color-one);
} */

#contact {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
#contact .text {
    cursor: default;
}
#contact span {
    color: var(--color-one);
    font-weight: 600;
    cursor: pointer;
}
#contact .map {
    text-align: center;
    width: 90%;
    max-width: 1000px;
    margin: auto;
}
#contact .map h3 {
    margin: 10px;
    font-size: 30px;
}
#contact .map iframe {
    border: 3px solid var(--color-one);
    border-radius: 15px;
}

#pricing h1 {
    text-align: center;
}
#pricing table {
    margin: auto;
    margin-bottom: 20px;
    width: 100%;
    max-width: 1000px;
    border-collapse: collapse;
}
#pricing table tr {
    height: 100px;
    border-top: 1px solid var(--color-one);
    border-bottom: 1px solid var(--color-one);
}
#pricing table td, #pricing table th {
    padding: 10px;
    border-top: 1px solid var(--color-one);
    border-bottom: 1px solid var(--color-one);
    text-align: center;
}
#pricing table td {
    min-width: 100px;
}

#news {
    position: relative;
}
#news .search {
    margin: auto;
    display: flex;
    justify-content: center;
    border: 1px solid var(--color-one);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}
#news .search input {
    flex: 1;
    border: none;
    background: white;
    padding: 5px;
    font-size: 16px;
}
#news .search button {
    padding: 5px 15px;
    border: none;
    background: none;
}
#news .search button img {
    height: 30px;
}
#news .list {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}
#news .post {
    flex-grow: 1;
    position: relative;
    width: 600px;
    padding: 5px 15px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 5px 20px rgba(85, 132, 172, 0.5);
    cursor: pointer;
}
#news .post .date {
    position: absolute;
    bottom: -5px;
    right: 5px;
    opacity: 0.5;
}
#news .page-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px;
}
#news .page-selector p {
    margin: 0;
    font-weight: bold;
}
#news .page-selector button {
    font-weight: bold;
    font-size: 16px;
    color: var(--color-one);
    border: none;
    background: none;
}

#news .bg {
    z-index: 400;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    opacity: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 0px;
    transition-duration: .625s;
}
#news .bg.closed {
    opacity: 0;
    filter: blur(20px);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    border-radius: 50%;
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    transition-duration: .3125s;
    /* transform: translate(-50%, -50%); */
}
#news .window {
    overflow: visible;
    min-width: 70vw;
    max-width: 70vw;
    min-height: 70vh;
    max-height: 70vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0px 0px 20px var(--color-one);
    background: white;
}
#news .bg.closed .window {
    display: none;
}
#news .window .heading {
    display: flex;
    align-items: center;
    gap: 10px;
}
#news .window .heading button {
    background: none;
    border: none;
    font-size: 15px;
    font-weight: 600;
    color: var(--color-one)
}
#news .window .heading .date {
    margin-left: auto;
    opacity: 0.5;
}
#news .window .body {
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    border-top: 1px solid var(--color-one);
}
#news .window .body p {
    margin: 0;
    text-align: justify;
}
#news .window .footer {
    border-top: 1px solid var(--color-one);
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
#news .window .footer .edit, #news .window .footer .delete {
    background: none;
    border: none;
    font-size: 18px;
    color: var(--color-one)
}
#news .window .footer .for-confirmation {
    position: relative;
}
#news .window .footer .delete {
    color: rgb(240, 0, 0);
}
#news .window .footer .confirm {
    position: absolute;
    bottom: 30px;
    max-height: 160px;
    overflow: hidden;
    width: 400px;
    padding: 10px;
    background-color: rgba(255, 255, 255, .75);
    border-radius: 15px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, .25);
    transition: .3125s;
}
#news .window .footer .confirm.closed {
    padding: 0;
    width: 0;
    opacity: 0;
    filter: blur(20px);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
}
#news .window .footer .confirm.closed h3, #news .window .footer .confirm.closed p, #news .window .footer .confirm.closed button {
    display: none;
}

#article {
    display: flex;
    flex-direction: column;
}
#article .heading {
    margin: 0px 15px;
    display: flex;
    align-items: center;
    gap: 15px;
}
#article .heading img {
    height: 200px;
    border-radius: 15px;
}
#article .heading h1 {
    color: var(--color-one);
    font-size: 80px;
}
#article .heading h1.long {
    font-size: 50px;
}
#article article {
    margin: auto;
    max-width: 1200px;
    text-align: justify;
    margin-bottom: 30px;
}
#article .table-of-contents {
    width: 80%;
    max-width: 1000px;
    align-self: flex-end;
    margin-right: -10px;
    padding: 10px;
    background: var(--color-one);
    color: white;
    border-radius: 15px 0 0 15px;
}
#article .table-of-contents ol {
    font-size: 18px;
    font-family: "Spectral SC", serif;
    text-decoration: underline;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
#article .table-of-contents ol li {
    cursor: pointer;
}

.contact {
    background: var(--color-two);
    padding: 15px 10px;
    margin: 0 -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.contact h3 {
    margin: 0;
    font-size: 28px;
}
.contact p {
    margin: 0;
    margin-bottom: 10px;
}
.contact form {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.contact .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, .5);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: .625s;
}
.contact .bg.closed {
    filter: blur(40px);
    opacity: 0;
    transform: rotateX(90deg);
}
.contact .bg h4 {
    margin: 0;
    font-size: 28px;
}
.contact .bg p {
    margin: 0;
}
.contact form input {
    font-size: 16px;
    border: none;
    border-radius: 5px;
    text-align: center;
}
.contact form textarea {
    font-size: 16px;
    overflow-y: auto;
    height: 150px;
    resize: none;
    border: none;
    border-radius: 5px;
}
.contact form button {
    background-color: var(--color-one);
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    transition: .3125s;
}
.contact form button:disabled {
    filter: grayscale(1);
}

#dashboard button {
    background: none;
    border: none;
    font-size: 18px;
    color: var(--color-one);
    font-weight: bold;
}
#dashboard tr {
    display: flex;
    gap: 15px;
}
#dashboard td {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
#dashboard .mail {
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .25);
    padding: 15px;
}
#dashboard .mail .holder {
    position: sticky;
    top: 110px;
    cursor: pointer;
}
#dashboard .mail .notification {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: var(--color-one);
}
#dashboard .posts {
    gap: 15px;
}
#dashboard .post {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, .25);
}
#edit form {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
#edit .title {
    padding: 5px;
    border-radius: 10px;
    border: 1px solid var(--color-one);
    font-size: 30px;
    font-family: "Spectral SC", serif;
    font-weight: bold;
}
#edit .text {
    padding: 5px;
    border-radius: 10px;
    border: 1px solid var(--color-one);
    font-size: 16px;
    height: 400px;
    resize: none;
}
#edit .date h4 {
    margin: 0;
}
#edit .send {
    padding: 5px 15px;
    font-size: 18px;
    font-family: "Spectral SC", serif;
    border: none;
    background: var(--color-one);
    color: white;
    border-radius: 5px;
}
#edit .day {
    margin-left: 15px;
}
#mailbox {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
#mailbox .mail {
    padding: 5px;
    width: 90%;
    max-width: 300px;
    border-radius: 15px;
    color: var(--color-one);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .25);
}
#mailbox .mail.unread {
    color: black;
}
#mail button {
    font-size: 17px;
}
#mail input {
    font-size: 17px;
}

.artemkhi p {
    margin: 25px;
    text-align: center;
}
.artemkhi span {
    color: var(--color-one);
    font-weight: bold;
    cursor: pointer;
}

@media all and (max-width: 600px) {
    .not-for-mobile, #con-menu .not-for-mobile {
        display: none;
    }
    .for-mobile, #con-menu .for-mobile {
        display: block;
    }
    header button.home img {
        height: 40px;
    }
    header button.home h1 {
        font-size: 20px;
        text-align: left;
    }
    header button.contact {
        display: none;
    }
    #home .news h3 {
        font-size: 30px;
    }
    #home .news .list .post {
        min-width: 200px;
    }
    #home .news .list .post:not(.more) p:not(.date) {
        display: none;
    }
    #home .result h2 {
        font-size: 20px;
    }
    #home .result h2 span {
        display: block;
    }
    #home .result .list .item {
        width: 95%;
    }
    #home .result .card-list .card {
        min-width: 200px;
    }
    #news .post .date {
        position: static;
        text-align: right;
        margin: 0;
    }
    #news .window {
        min-width: 98vw;
        min-height: 98vh;
    }
    #news .window .body {
        padding-bottom: 100px;
    }
    #article .heading h1 {
        font-size: 30px !important;
    }
    #article article {
        text-align: left;
    }
    #article .table-of-contents {
        width: 100%;
        border-radius: 0;
    }
    #article .table-of-contents ol {
        font-size: 15px;
    }
    #pricing .for-table {
        overflow-x: scroll;
    }
    .mob-con {
        position: fixed;
        bottom: 10px;
        right: 20px;
    }
    .mob-con button.contact {
        border: none;
        border-radius: 35px;
        padding: 10px;
        background: rgba(85, 132, 172, .75);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .25);
    }
    .mob-con button.contact img {
        height: 50px;
    }
    .mob-con #con-menu {
        position: fixed;
        top: auto;
        bottom: 85px;
        right: 5px;
        width: 90vw;
        overflow-x: scroll;
    }
    .mob-con #con-menu.closed {
        top: auto;
        bottom: 50px;
        right: 0;
    }
    #dashboard tr {
        flex-direction: column;
    }
}