@keyframes ldio-x2uulkbinc-1 {
    0% { top: 10px; height: 80px }
    50% { top: 30px; height: 40px }
    100% { top: 30px; height: 40px }
  }
  @keyframes ldio-x2uulkbinc-2 {
    0% { top: 15px; height: 70px }
    50% { top: 30px; height: 40px }
    100% { top: 30px; height: 40px }
  }
  @keyframes ldio-x2uulkbinc-3 {
    0% { top: 20px; height: 60px }
    50% { top: 30px; height: 40px }
    100% { top: 30px; height: 40px }
  }
  .ldio-x2uulkbinc div { position: absolute; width: 15px }.ldio-x2uulkbinc div:nth-child(1) {
    left: 17.5px;
    background: #77a6ce;
    animation: ldio-x2uulkbinc-1 1.6666666666666667s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: -0.33333333333333337s
  }
  .ldio-x2uulkbinc div:nth-child(2) {
    left: 42.5px;
    background: #5584ac;
    animation: ldio-x2uulkbinc-2 1.6666666666666667s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: -0.16666666666666669s
  }
  .ldio-x2uulkbinc div:nth-child(3) {
    left: 67.5px;
    background: #33628a;
    animation: ldio-x2uulkbinc-3 1.6666666666666667s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: undefineds
  }
  
  .loadingio-spinner-pulse-nq4q5u6dq7r {
    width: 80px;
    height: 80px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-x2uulkbinc {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.8);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-x2uulkbinc div { box-sizing: content-box; }